<template>
    <div id="settings">
        <p class="main_font">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin velit
            erat, porta eu nisl ut, porta lobortis elit. Curabitur nulla est,
            venenatis sed nibh non, tempus suscipit lectus. Ut ipsum lorem,
            posuere ut erat quis, venenatis dignissim arcu. Aenean elit tellus,
            posuere in convallis id, consequat ac metus. Phasellus sit amet
            faucibus mauris. Quisque massa leo, aliquet sed tincidunt at,
            accumsan sit amet sem. Vestibulum nec ultricies diam, semper
            vulputate risus. Nullam convallis risus nec eros elementum, quis
            ultrices risus suscipit. Mauris fringilla mollis nisl, dictum
            aliquet eros malesuada euismod. Sed ultrices tincidunt scelerisque.
            Curabitur congue, metus vitae hendrerit dignissim, diam felis
            molestie dolor, in porta nibh dui ut nunc. Nullam sit amet tincidunt
            ex. Etiam molestie, mauris id fringilla viverra, neque ligula luctus
            est, at malesuada mauris eros ac dui.
        </p>

        <p class="main_font">
            Nulla facilisi. Curabitur pellentesque mollis libero in eleifend.
            Nunc malesuada vestibulum nunc eu bibendum. Praesent dapibus arcu
            vitae lorem lobortis luctus. Nunc ut porttitor quam. Sed at
            sollicitudin felis. Donec laoreet neque non lacus tincidunt
            efficitur. Fusce pretium nisl in consectetur sodales. Aenean
            lobortis lobortis dolor. Morbi ornare metus vel efficitur ultrices.
            Maecenas blandit condimentum felis sit amet consequat.
        </p>

        <p class="main_font">
            Vestibulum nec lacus nec magna scelerisque tempus ut vitae nulla.
            Aenean in dictum orci. Fusce ut neque condimentum diam tempus
            fringilla sit amet at mi. Proin volutpat, odio sit amet sollicitudin
            condimentum, dolor augue eleifend ante, a dapibus nulla lacus non
            purus. Aliquam tincidunt mauris quis luctus ultricies. Proin sit
            amet interdum turpis. Aenean iaculis diam et augue dapibus, et
            accumsan dolor tincidunt. Morbi sit amet pharetra nibh. Maecenas
            porta ultrices ornare. Nam tempor dignissim purus id placerat. Nunc
            ut mauris sit amet purus sagittis rutrum sit amet nec lectus. In
            porttitor tempor lacus, vel hendrerit purus feugiat a.
        </p>

        <p class="main_font">
            Mauris vitae eros vitae lectus finibus pretium. Curabitur laoreet
            ipsum neque, at dictum purus faucibus non. Aliquam accumsan augue in
            fermentum ullamcorper. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Quisque vestibulum euismod ipsum, et imperdiet erat
            faucibus nec. Vivamus at velit tincidunt, venenatis ipsum ac,
            pretium massa. Curabitur vitae commodo mauris, a ornare libero. Ut
            diam tortor, auctor id porta quis, porttitor nec risus. Fusce porta
            ultrices libero. Sed rutrum eu urna nec facilisis. Quisque non
            tortor ac metus blandit varius nec eu sapien. Proin consequat
            euismod tellus sed ultricies. Mauris quis dui et arcu hendrerit
            placerat. Pellentesque lectus nisl, euismod eu massa ut, malesuada
            eleifend justo. Nulla a tellus sit amet metus cursus blandit.
        </p>

        <p class="main_font">
            Praesent aliquam pharetra suscipit. Phasellus mattis leo ut cursus
            efficitur. Curabitur mi tellus, posuere in condimentum non, congue
            volutpat libero. Integer non ante vitae enim ullamcorper bibendum.
            Curabitur commodo id magna in fermentum. Sed convallis consequat
            dolor, at ultrices diam ultricies sollicitudin. Curabitur hendrerit,
            sem eget fermentum commodo, metus libero consequat lacus, sed
            sollicitudin quam augue vitae lorem. Vivamus nulla erat, ultrices
            vel laoreet sed, dictum vitae odio.
        </p>
    </div>
</template>

<script>
function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    data() {
        return {
            header: {
                title: 'Settings',
            },
            yo: true,
        };
    },

    mounted() {
        console.log('settings');
        this.setHeader();
    },

    methods: {
        setHeader,
    },
};
</script>

<style scoped>
#settings {
    position: absolute;
    top: var(--header_height);
}
</style>
